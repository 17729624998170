<template>
    <ODataLookup :data-object="dsActivitiesLookup" contextField="OrgUnitIdPath">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">                
            </span>
        </template>
        
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="TypeAndName" width="300"></OColumn>
        <OColumn field="Description" width="300"></OColumn>
        <OColumn field="OrgUnit" width="180"></OColumn>
    </ODataLookup>
</template>

<script setup>
    import { defineProps } from 'vue';
    import { ODataLookup } from 'o365-datalookup';

    const props = defineProps({
        is: String,
        IdPath: String,      
        textInputValue: String,
        textInput: Boolean
    });

    const dsActivitiesLookup = $getDataObjectById("dsComplete_ActivitiesLookup");
</script>